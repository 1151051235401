<template>
    <div v-click-outside="close" :class="styleClasses">
        <div v-if="active">
            <slot name="content"></slot>
        </div>

        <div v-else @click="open">
            <slot name="display"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const InplaceProps = Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class Inplace extends InplaceProps {
    active = false;

    get styleClasses() {
        let classes = 'inplace-wrapper';

        if (this.disabled) {
            classes = `${classes} disabled`;
        }

        return classes;
    }

    open() {
        if (this.disabled) {
            return false;
        }

        this.active = true;
    }

    close() {
        this.active = false;
    }
}
</script>

<style lang="scss" scoped>
.inplace-wrapper::v-deep {
    width: 100%;

    &:not(.disabled) {
        cursor: pointer;
    }
}
</style>
