export enum ContentSourceStatus {
    Fetching = 0,
    Success = 1,
    Failed = 2
}

export interface ContentSource {
    id: number | null;
    announcement_id: number;
    status: ContentSourceStatus | null;
    url: string;
    title: string | null;
    content: string | null;
}
