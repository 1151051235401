<template>
    <inplace :disabled="disabled">
        <template #display>
            <v-tooltip bottom>
                <template #activator="{ attr, on }">
                    <span v-bind="attr" v-on="on">
                        {{ displayText }}
                    </span>
                </template>
                <span>Click on any part of the transcript to edit it</span>
            </v-tooltip>
        </template>
        <template #content>
            <a-text-input
                rules="required"
                dense
                auto-grow
                textarea
                rows="1"
                hide-details
                :loading="loading"
                :disabled="loading"
                v-bind="$attrs"
                single-line
                v-on="$listeners"
            />
        </template>
    </inplace>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { Inplace } from '@/components/Inplace';

const CaptionInputProps = Vue.extend({
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        Inplace,
        ATextInput
    }
})
export default class CaptionInput extends CaptionInputProps {
    get displayText() {
        return this.$attrs.value === '' ? 'Click to enter' : this.$attrs.value;
    }
}
</script>
