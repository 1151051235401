<template>
    <v-container class="amp-module-page">
        <a-form
            ref="form"
            :auto-submit="!isModuleBusy"
            warn-dirty
            @auto-submit="autoSave"
        >
            <v-row v-if="hasPublishIssues">
                <v-col md="8">
                    <v-card>
                        <v-card-text>
                            <a-alert
                                v-for="(issue, i) in publishIssues"
                                :key="i"
                                :message="issue.message"
                            />
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <amp-expansion-panel
                v-if="canCreateInterviewStylePodcast"
                label="Background Info"
                :expand="expandBackgroundInfo"
                @update:expand="expandBackgroundInfo = $event"
            >
                <!-- Podcast Style -->
                <amp-row>
                    <template #input>
                        <a-select-input
                            v-model="podcast.style_type"
                            class="py-6"
                            hide-details
                            :items="podcastStyleItems"
                            label="Podcast Style"
                            :loading="loading"
                            :disabled="loading"
                        />
                    </template>
                </amp-row>

                <template v-if="isInterviewStylePodcast">
                    <!-- Topic 1 -->
                    <amp-row>
                        <template #input>
                            <a-text-input
                                v-model="podcast.topic_1"
                                field="topic_1"
                                label="Topic 1"
                                rules="required"
                                :loading="loading"
                                :disabled="loading"
                                :custom-messages="{
                                    required:
                                        'Please enter a concise summary of the first podcast topic'
                                }"
                                @focus="showInfoAndFit('topic_1')"
                            />
                        </template>

                        <template #validation>
                            <a-alert
                                class="d-none"
                                :class="{
                                    'position--absolute':
                                        $vuetify.breakpoint.mdAndUp
                                }"
                                data-alert="alert-topic_1"
                                data-type="toggleable-info"
                                message="Enter two concise topics summarizing the focus of the podcast discussion. Each topic should be related to the blog post and provide a clear direction for conversation. Keep the topics brief, specific, and engaging. Example: 'The Impact of Remote Work on Productivity' or 'How Mindfulness Enhances Creativity.'"
                                type="info"
                            />
                        </template>
                    </amp-row>

                    <!-- Topic 2 -->
                    <amp-row>
                        <template #input>
                            <a-text-input
                                v-model="podcast.topic_2"
                                field="topic_2"
                                label="Topic 2"
                                rules="required"
                                :loading="loading"
                                :disabled="loading"
                                :custom-messages="{
                                    required:
                                        'Please enter a concise summary of the second podcast topic'
                                }"
                                @focus="showInfoAndFit('topic_1')"
                            />
                        </template>
                    </amp-row>

                    <!-- Content Sources -->
                    <amp-row>
                        <template #input>
                            <small class="mb-1">
                                Web pages with background info provide context,
                                helping us generate relevant content
                            </small>

                            <a-text-input
                                v-for="(source, i) in contentSources"
                                :key="i"
                                v-model="source.url"
                                :field="`content_source_${i}`"
                                label="Webpage with Background Info"
                                rules="url|required"
                                :custom-messages="{
                                    url: 'Please enter a valid URL',
                                    required:
                                        'Webpage with Background Info is required'
                                }"
                                :loading="loading"
                                @focus="showInfoAndFit('contentSources')"
                            />
                        </template>

                        <template #validation>
                            <a-alert
                                class="d-none mt-md-6"
                                data-alert="alert-contentSources"
                                data-type="toggleable-info"
                                message="Enter URLs that provide relevant background information for this podcast discussion. The content from these sources will help enrich the conversation. Ensure the links are publicly accessible and provide valuable insights. Example: 'https://example.com/article-on-topic'."
                                type="info"
                            />

                            <a-alert
                                v-if="containsLinks"
                                class="mt-6"
                                :type="
                                    allLinksReachable ? 'success' : 'warning'
                                "
                            >
                                <span v-if="allLinksReachable">
                                    All links have been validated and are
                                    accessible.
                                </span>

                                <span v-else>
                                    One or more of the links provided could not
                                    be reached. Please ensure that all links
                                    provided are accessible.
                                </span>
                            </a-alert>
                        </template>
                    </amp-row>

                    <!-- About Company -->
                    <amp-row>
                        <template #input>
                            <a-text-input
                                v-model="aboutCompany"
                                textarea
                                rows="3"
                                label="About the company"
                                :rules="{
                                    required: true,
                                    min: 50,
                                    max: 2048
                                }"
                                placeholder="Start with, The company..."
                                :loading="loading"
                                :disabled="loading"
                                :custom-messages="{
                                    required:
                                        'Please provide a brief description of your company.'
                                }"
                                @focus="showInfoAndFit('companyInfo')"
                            />
                        </template>

                        <template #validation>
                            <a-alert
                                class="d-none mt-md-6"
                                data-alert="alert-companyInfo"
                                data-type="toggleable-info"
                                message="Provide a brief and clear description of your company, including its mission, key services, or main products. This helps set the right context for the podcast discussion. Example: 'The company specializes in eco-friendly packaging solutions, helping businesses reduce their carbon footprint through sustainable materials and innovative designs.'"
                                type="info"
                            />
                        </template>
                    </amp-row>

                    <!-- action buttons -->
                    <amp-row>
                        <template #input>
                            <div class="d-flex">
                                <v-spacer />
                                <v-btn
                                    color="primary mb-2"
                                    :disabled="
                                        isGenerating || generatingTranscript
                                    "
                                    @click.stop="openGenerateTranscriptModal"
                                >
                                    {{
                                        hasTranscript
                                            ? 'Regenerate'
                                            : 'Generate'
                                    }}
                                    Transcript
                                </v-btn>
                            </div>
                        </template>

                        <template #validation>
                            <a-alert
                                v-if="hasTranscriptErrors"
                                type="error"
                                :loading="loading"
                            >
                                <span>Please resolve following errors:</span>
                                <ul>
                                    <li
                                        v-for="(error, i) in transcriptErrors"
                                        :key="i"
                                    >
                                        {{ error }}
                                    </li>
                                </ul>
                            </a-alert>
                        </template>
                    </amp-row>
                </template>
            </amp-expansion-panel>

            <!-- standard podcast -->
            <template v-if="isStandardPodcast">
                <!-- headline -->
                <amp-row-first>
                    <template #input>
                        <div class="font-weight-bold mb-4">Podcast</div>

                        <a-text-input
                            ref="headline"
                            v-model="podcast.headline"
                            :loading="loading"
                            :disabled="loading"
                            label="Headline"
                            rules="required|max:80"
                            rows="1"
                            auto-grow
                            textarea
                            observed
                        />
                    </template>
                    <template #validation>
                        <amp-validation
                            :input="$refs.headline"
                            :loading="isLoading"
                        />
                    </template>
                </amp-row-first>

                <!-- summary -->
                <amp-row>
                    <template #input>
                        <a-content-editor
                            ref="summary"
                            v-model="podcast.summary"
                            :loading="loading"
                            :disabled="loading"
                            label="Summary"
                            rules="required|words:0,45"
                            :counter-value="getWordsCounter(45)"
                            :no-first-person="false"
                            :debounce="500"
                            observed
                            one-line
                        />
                    </template>

                    <template #validation>
                        <amp-validation
                            :input="$refs.summary"
                            :loading="isLoading"
                        >
                            <a-alert
                                :type="podcast.summary ? 'success' : 'info'"
                                :message="
                                    actualWordsCount(
                                        podcast.summary,
                                        0,
                                        45,
                                        'Summary'
                                    )
                                "
                            />
                            <a-alert
                                v-if="containsLinks"
                                :type="
                                    summaryText === ''
                                        ? 'info'
                                        : allLinksReachable
                                          ? 'success'
                                          : 'warning'
                                "
                            >
                                <span v-if="allLinksReachable">
                                    All links have been validated and are
                                    accessible.
                                </span>

                                <span v-else>
                                    One or more of the links provided could not
                                    be reached. Please ensure that all links
                                    provided are accessible.
                                </span>
                            </a-alert>
                        </amp-validation>
                    </template>
                </amp-row>

                <!-- script -->
                <amp-row>
                    <template #input>
                        <a-text-input
                            ref="content"
                            v-model="content"
                            :loading="isLoading"
                            label="Podcast Script"
                            rules="required|words:300,1000"
                            :counter-value="getWordsCounter(1000)"
                            auto-grow
                            textarea
                            observed
                        />
                    </template>
                    <template #validation>
                        <amp-validation
                            :input="$refs.content"
                            :loading="isLoading"
                        >
                            <a-alert
                                :type="content ? 'success' : 'info'"
                                :message="
                                    actualWordsCount(
                                        content,
                                        300,
                                        1000,
                                        'Content'
                                    )
                                "
                            />
                        </amp-validation>
                    </template>
                </amp-row>

                <!-- voice -->
                <amp-row>
                    <template #input>
                        <v-row class="ma-0">
                            <v-col class="pa-0 flex-grow-1">
                                <a-select-input
                                    v-model="speakerOneVoiceId"
                                    :loading="loading"
                                    :disabled="loading"
                                    label="Voice"
                                    :items="video_voices"
                                    item-text="voice_name"
                                    item-value="id"
                                />
                            </v-col>
                            <v-col class="pa-0 pl-4 pt-2 flex-grow-0">
                                <audio-player-chip
                                    :src="getVoiceSample(speakerOneVoiceId)"
                                />
                            </v-col>
                        </v-row>
                    </template>

                    <template #validation>
                        <a-alert type="info">
                            Copy below will be podcasted with selected Voice,
                            use play to try different voice variations.
                        </a-alert>
                    </template>
                </amp-row>

                <!-- media -->
                <amp-row-last>
                    <template #input>
                        <div class="font-weight-bold">Media</div>
                        <media-preview-list
                            :media-resources="podcast.media_resources"
                            :user-id="podcast.user_id"
                            :allow-add="!hasMedia"
                            :allow-delete="false"
                            :options="mediaOptions"
                            module-title="Podcast"
                            class="my-4"
                            :loading="loading"
                            :disabled="loading"
                            @mediaFiles="onMediaFilesUpdate"
                        />

                        <validation-provider
                            ref="media_resources"
                            name="media_resources"
                        >
                            <input
                                v-model="podcast.media_resources"
                                type="hidden"
                            />
                        </validation-provider>
                    </template>
                </amp-row-last>
            </template>

            <!-- interview-style podcast -->
            <template v-if="isInterviewStylePodcast">
                <amp-loading-card
                    v-if="generatingTranscript"
                    label="Generating Podcast Script"
                />

                <!-- transcript -->
                <v-expand-transition>
                    <div v-if="hasTranscript && !generatingTranscript">
                        <!-- headline -->
                        <amp-row-first>
                            <template #input>
                                <div class="font-weight-bold mb-4">
                                    Inteview-style Podcast
                                </div>

                                <a-text-input
                                    ref="headline"
                                    v-model="podcast.headline"
                                    :loading="loading"
                                    :disabled="loading"
                                    label="Headline"
                                    rules="required|max:80"
                                    rows="1"
                                    auto-grow
                                    textarea
                                    observed
                                />
                            </template>
                            <template #validation>
                                <amp-validation
                                    :input="$refs.headline"
                                    :loading="isLoading"
                                />
                            </template>
                        </amp-row-first>

                        <!-- summary -->
                        <amp-row>
                            <template #input>
                                <a-content-editor
                                    ref="summary"
                                    v-model="podcast.summary"
                                    :loading="loading"
                                    :disabled="loading"
                                    label="Summary"
                                    rules="required|words:0,45"
                                    :counter-value="getWordsCounter(45)"
                                    :no-first-person="false"
                                    :debounce="500"
                                    observed
                                    one-line
                                />
                            </template>
                            <template #validation>
                                <amp-validation
                                    :input="$refs.summary"
                                    :loading="isLoading"
                                >
                                    <a-alert
                                        :type="
                                            podcast.summary ? 'success' : 'info'
                                        "
                                        :message="
                                            actualWordsCount(
                                                podcast.summary,
                                                0,
                                                45,
                                                'Summary'
                                            )
                                        "
                                    />
                                    <a-alert
                                        v-if="containsLinks"
                                        :type="
                                            summaryText === ''
                                                ? 'info'
                                                : allLinksReachable
                                                  ? 'success'
                                                  : 'warning'
                                        "
                                    >
                                        <span v-if="allLinksReachable">
                                            All links have been validated and
                                            are accessible.
                                        </span>

                                        <span v-else>
                                            One or more of the links provided
                                            could not be reached. Please ensure
                                            that all links provided are
                                            accessible.
                                        </span>
                                    </a-alert>
                                </amp-validation>
                            </template>
                        </amp-row>

                        <!-- transcript -->
                        <amp-row>
                            <template #input>
                                <div class="font-weight-bold mb-6">
                                    Transcript
                                </div>

                                <div
                                    v-for="(
                                        caption, i
                                    ) in podcast.podcast_captions"
                                    :key="i"
                                    class="transcript-caption"
                                    :class="{
                                        host:
                                            caption.speaker_type ===
                                            speakerType.SpeakerTypeSpeakerOne,
                                        sponsor:
                                            caption.speaker_type ===
                                            speakerType.SpeakerTypeSponsor
                                    }"
                                >
                                    <v-avatar class="rounded">
                                        <v-img
                                            :src="
                                                getVoiceAvatarSrc(
                                                    caption.video_voice_id
                                                )
                                            "
                                        />
                                    </v-avatar>

                                    <div class="content">
                                        <span
                                            class="font-weight-black text-small"
                                        >
                                            {{
                                                caption.speaker_type ===
                                                speakerType.SpeakerTypeSponsor
                                                    ? 'Sponsor'
                                                    : getVoiceName(
                                                          caption.video_voice_id
                                                      )
                                            }}
                                        </span>
                                        <caption-input
                                            v-model="caption.content"
                                            class="caption-input"
                                        />
                                    </div>
                                </div>
                            </template>

                            <template #validation>
                                <a-alert
                                    v-if="!hasCompleteTranscript"
                                    class="mt-6"
                                    type="error"
                                >
                                    No blank fields allowed in the transcript
                                </a-alert>
                            </template>
                        </amp-row>

                        <!-- intro/outro audio -->
                        <amp-row>
                            <template #input>
                                <div class="font-weight-bold pt-4 mb-2">
                                    Additional Settings
                                </div>

                                <div class="d-flex align-center justify-center">
                                    <a-select-input
                                        v-model="podcast.audio_clip_url"
                                        class="mr-4"
                                        :items="audioClips"
                                        label="Intro/Outro Audio"
                                        :rules="audioClipRules"
                                        item-text="title"
                                        item-value="url"
                                        :loading="loading"
                                        :disabled="loading"
                                    />
                                    <audio-player-chip
                                        :src="activeAudioClipSrc"
                                    />
                                </div>
                            </template>
                        </amp-row>

                        <!-- speaker one voice -->
                        <amp-row>
                            <template #input>
                                <v-row class="ma-0">
                                    <v-col class="pa-0 flex-grow-1">
                                        <a-select-input
                                            v-model="speakerOneVoiceId"
                                            :loading="loading"
                                            :disabled="loading"
                                            label="Speaker one voice"
                                            :items="video_voices"
                                            item-text="voice_name"
                                            item-value="id"
                                            :item-disabled="
                                                disableSelectedSpeakerTwoVoice
                                            "
                                        />
                                    </v-col>
                                    <v-col class="pa-0 pl-4 pt-2 flex-grow-0">
                                        <audio-player-chip
                                            :src="
                                                getVoiceSample(
                                                    speakerOneVoiceId
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </template>
                        </amp-row>

                        <!-- speaker two voice -->
                        <amp-row>
                            <template #input>
                                <v-row class="ma-0">
                                    <v-col class="pa-0 flex-grow-1">
                                        <a-select-input
                                            v-model="speakerTwoVoiceId"
                                            :loading="loading"
                                            :disabled="loading"
                                            label="Speaker two voice"
                                            :items="video_voices"
                                            item-text="voice_name"
                                            item-value="id"
                                            :item-disabled="
                                                disableSelectedSpeakerOneVoice
                                            "
                                        />
                                    </v-col>
                                    <v-col class="pa-0 pl-4 pt-2 flex-grow-0">
                                        <audio-player-chip
                                            :src="
                                                getVoiceSample(
                                                    speakerTwoVoiceId
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </template>
                        </amp-row>

                        <!-- media -->
                        <amp-row-last>
                            <template #input>
                                <div class="font-weight-bold pt-4">Media</div>
                                <media-preview-list
                                    :media-resources="podcast.media_resources"
                                    :user-id="podcast.user_id"
                                    :allow-add="!hasMedia"
                                    :allow-delete="false"
                                    :options="mediaOptions"
                                    module-title="Podcast"
                                    class="mt-2 mb-4"
                                    :loading="loading"
                                    :disabled="loading"
                                    @mediaFiles="onMediaFilesUpdate"
                                />

                                <validation-provider
                                    ref="media_resources"
                                    name="media_resources"
                                >
                                    <input
                                        v-model="podcast.media_resources"
                                        type="hidden"
                                    />
                                </validation-provider>
                            </template>
                        </amp-row-last>
                    </div>
                </v-expand-transition>
            </template>

            <generate-transcript-modal
                :loading="generatingTranscript"
                @confirm="generateTranscript"
            />

            <generate-standard-podcast-modal
                :loading="generatingTranscript"
                @cancel="onCancelStandardPodcastGeneration"
                @confirm="generateStandardPodcast"
            />
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Route, NavigationGuardNext } from 'vue-router';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import {
    Endpoint,
    WordsCounter,
    ValidateLinksReachability,
    InfoFieldMixin
} from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { AudioPlayerChip } from '@/components/AudioPlayerChip';
import { MediaPreviewList } from '@/components/Media';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AContentEditor } from '@/components/AForm/Inputs/AContentEditor';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';

import { areMediaResourcesEqual, stripHtml } from '@/utils/helpers';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';

import type { AmpModules, Announcement } from '@/types/Announcement';
import type { FormMediaResource } from '@/types/Media';
import {
    PodcastStatus,
    PodcastStyleType,
    PodcastAudioClip,
    Podcast as TPodcast,
    SpeakerType
} from '@/types/Podcast';
import type { VideoVoice } from '@/types/Video';
import type { ModuleLink } from '@/types/ModuleLink';
import type { MediaResource } from '@/types/MediaResource';

import GenerateTranscriptModal from './GenerateTranscriptModal.vue';
import GenerateStandardPodcastModal from './GenerateStandardPodcastModal.vue';
import CaptionInput from '@/pages/Announcements/Edit/Podcast/CaptionInput.vue';
import { AmpExpansionPanel, AmpLoadingCard } from '@/components/AmpModule';
import { ContentSource, ContentSourceStatus } from '@/types/ContentSource';

type DefaultSpeakerVoices = {
    speaker_one: number;
    speaker_two: number;
};

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        AForm,
        AAlert,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpValidation,
        ATextInput,
        AContentEditor,
        ASelectInput,
        AudioPlayerChip,
        MediaPreviewList,
        GenerateTranscriptModal,
        GenerateStandardPodcastModal,
        CaptionInput,
        AmpExpansionPanel,
        AmpLoadingCard
    },
    computed: {
        ...mapGetters('user', ['isEditor'])
    }
})
export default class Podcast extends mixins(
    Endpoint,
    WordsCounter,
    ValidateLinksReachability,
    InfoFieldMixin
) {
    isEditor!: boolean;

    $refs!: {
        form: InstanceType<typeof AForm>;
        headline: InstanceType<typeof ATextInput>;
        summary: InstanceType<typeof ATextInput>;
        content: InstanceType<typeof ATextInput>;
        media_resources: InstanceType<typeof ValidationProvider>;
    };

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @InjectReactive({
        from: 'isModuleBusy',
        default() {
            return false;
        }
    })
    isModuleBusy!: boolean;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    podcast: Partial<TPodcast> = {
        media_resources: [],
        podcast_captions: [
            {
                podcast_id: 0,
                video_voice_id: 75,
                content: '',
                speaker_type: 1
            }
        ]
    };

    @Watch('podcast.media_resources', { deep: true })
    onMediaResourcesChange(a: MediaResource[], b: MediaResource[]) {
        if (this.isReadyForChanges && !areMediaResourcesEqual(a, b)) {
            this.$refs.media_resources.setFlags({
                dirty: true,
                changed: true,
                touched: true
            });
        }
    }

    video_voices: VideoVoice[] = [];

    endpoint = '/podcasts/edit';

    isSaving = false;
    isReadyForChanges = false;
    generatePreview = false;

    @Watch('podcast.summary')
    onSummaryTextChange(value: string) {
        this.validateLinksReachability(value);
    }

    link: ModuleLink[] = [
        {
            type: 'primary',
            label: 'Review',
            to: this.reviewLink
        }
    ];

    showTranscript = false;

    autoCheckStatusInterval: ReturnType<typeof setInterval> | null = null;

    @Watch('podcast.style_type')
    onPodcastStyleChange(
        style: PodcastStyleType,
        prevStyle: PodcastStyleType | undefined
    ) {
        if (prevStyle !== undefined) {
            if (style === PodcastStyleType.INTERVIEW) {
                if (this.hasTranscript) {
                    this.showTranscript = true;
                }
            }

            if (style === PodcastStyleType.STANDARD && this.hasTranscript) {
                this.openGenerateStandardPodcastModal();
            }
        }
    }

    audioClips: PodcastAudioClip[] = [];
    aboutCompany = '';

    contentSources: Array<ContentSource> = [];
    @Watch('contentSources', { deep: true })
    onLinkChange(contentSources: Array<ContentSource>) {
        // @FIXME - if you remove the space after the comma, a string is sent in the request instead of an array.
        const allUrls = contentSources.map(item => item.url).join(', ');
        this.validateLinksReachability(allUrls, false);
    }

    transcriptErrors: Array<string> = [];
    isGenerating = false;

    speakerType = SpeakerType;

    speakerOneVoiceId = 0;
    @Watch('speakerOneVoiceId')
    onSpeakerOneVoiceChange(voiceId: number) {
        this.updateCaptionVoiceForSpeaker(
            SpeakerType.SpeakerTypeSpeakerOne,
            voiceId
        );
    }

    speakerTwoVoiceId = 0;
    @Watch('speakerTwoVoiceId')
    onSpeakerTwoVoiceChange(voiceId: number) {
        this.updateCaptionVoiceForSpeaker(
            SpeakerType.SpeakerTypeSpeakerTwo,
            voiceId
        );
    }

    defaultSpeakerVoices: DefaultSpeakerVoices = {
        speaker_one: 0,
        speaker_two: 0
    };

    expandBackgroundInfo = true;

    get moduleId() {
        return this.modules?.podcast_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get summaryText() {
        return stripHtml(this.podcast.summary || '');
    }

    get hasMedia() {
        return this.podcast.media_resources?.length;
    }

    get publishIssues() {
        return this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-publish-podcast`
        );
    }

    get mediaOptions() {
        return {
            announcement_id: this.announcementId
        };
    }

    get reviewLink() {
        return `/announcements/review/${this.announcementId}/podcast`;
    }

    get captions() {
        if (
            !this.podcast?.podcast_captions ||
            this.podcast.podcast_captions.length === 0
        ) {
            const caption = {
                podcast_id: this.podcast?.id || 0,
                content: '',
                video_voice_id: 90,
                speaker_type: 1
            };
            this.podcast.podcast_captions = [caption];
        }
        return this.podcast?.podcast_captions;
    }

    get content() {
        return this.captions[0]?.content || '';
    }

    set content(value) {
        this.captions[0].content = value;
    }

    get hasTranscript() {
        return (
            this.podcast.podcast_captions &&
            this.podcast.podcast_captions.length > 1
        );
    }

    get loading() {
        return this.isLoading || this.generatingTranscript || this.isGenerating;
    }

    get generatingTranscript() {
        return this.podcast.status === PodcastStatus.GeneratingTranscript;
    }

    get podcastStyleItems() {
        return [
            {
                text: 'Standard',
                value: PodcastStyleType.STANDARD
            },
            {
                text: 'Interview',
                value: PodcastStyleType.INTERVIEW
            }
        ];
    }

    get hasAllTopics() {
        const { topic_1, topic_2 } = this.podcast;
        return [topic_1, topic_2].includes('') === false;
    }

    get hasCompleteTranscript() {
        const emptyCaptions = this.podcast.podcast_captions?.filter(
            caption => caption.content.trim() === ''
        );

        return emptyCaptions?.length === 0;
    }

    get isStandardPodcast() {
        return this.podcast.style_type === PodcastStyleType.STANDARD;
    }

    get isInterviewStylePodcast() {
        return this.podcast.style_type === PodcastStyleType.INTERVIEW;
    }

    get activeAudioClipSrc() {
        if (this.podcast?.audio_clip_url) {
            return this.podcast?.audio_clip_url;
        }
        return '';
    }

    get audioClipRules() {
        return this.isInterviewStylePodcast ? 'required' : '';
    }

    get isInitialized() {
        return Boolean(this.podcast.id);
    }

    get hasTranscriptErrors() {
        return !!this.transcriptErrors.length;
    }

    get isValid() {
        return this.$refs.form.isValidated ? this.$refs.form.isValid : true;
    }

    get hasPublishIssues() {
        return this.publishIssues && this.publishIssues.length > 0;
    }

    get canCreateInterviewStylePodcast() {
        return this.podcast.user?.has_ai_features;
    }

    onMounted() {
        if (this.moduleId) {
            this.setPrePublishHook();

            this.load();
        } else {
            this.askToCreate();
        }
    }

    setPrePublishHook(isSet = true) {
        this.$emit('pre-publish', isSet ? this.prePublish.bind(this) : null);
    }

    askToCreate() {
        if (this.modules && !this.moduleId) {
            this.$emit('create', this.endpoint);
        }
    }

    // @todo - this is in both news-article and here temporarily; remove once new FE implemented
    ensureMinContentSources(
        sources: ContentSource[],
        announcementId: number
    ): ContentSource[] {
        while (sources.length < 2) {
            sources.push({
                id: null,
                status: null,
                title: null,
                content: null,
                announcement_id: announcementId,
                url: ''
            });
        }
        return sources;
    }

    onData(data: {
        podcast: TPodcast;
        video_voices: VideoVoice[];
        audio_clips: PodcastAudioClip[];
        default_speaker_voices: DefaultSpeakerVoices;
    }) {
        if (data.podcast) {
            return this.commit(data).then(() => {
                this.protectRoute();
            });
        } else {
            this.review();
        }
    }

    async commit(data: {
        podcast: TPodcast;
        video_voices: VideoVoice[];
        audio_clips: PodcastAudioClip[];
        default_speaker_voices: DefaultSpeakerVoices;
        errors?: string[];
    }) {
        this.softCommit(data.podcast);

        this.podcast.podcast_captions = data.podcast.podcast_captions;

        this.defaultSpeakerVoices = data.default_speaker_voices;

        this.speakerOneVoiceId = this.getSpeakerVoiceId(
            SpeakerType.SpeakerTypeSpeakerOne
        );
        this.speakerTwoVoiceId = this.getSpeakerVoiceId(
            SpeakerType.SpeakerTypeSpeakerTwo
        );

        if (data.audio_clips) {
            this.audioClips = data.audio_clips;
        }

        if (data.video_voices) {
            this.video_voices = data.video_voices;
        }

        this.aboutCompany = data.podcast.announcement?.company.about ?? '';

        this.contentSources = this.ensureMinContentSources(
            data.podcast.announcement?.content_sources ?? [],
            data.podcast.announcement_id
        );

        if (this.podcast.style_type === PodcastStyleType.INTERVIEW) {
            this.$nextTick(() => {
                this.initInterviewStylePodcast();
            });
        }

        this.emitLinks();

        await this.setSaved();

        this.isReadyForChanges = true;

        return data;
    }

    softCommit(data: TPodcast) {
        if (this.isInitialized) {
            const softProperties: Array<keyof TPodcast> = [
                'is_editable',
                'is_awaiting_audio_preview',
                'is_live',
                'is_publishable',
                'status',
                'status_string'
            ];

            (Object.keys(data) as Array<keyof TPodcast>)
                .filter(key => softProperties.includes(key))
                .forEach(key => {
                    this.$set(this.podcast, key, data[key]);
                });
        } else {
            this.podcast = data;
        }
    }

    async save(foreground = true) {
        this.setSaving();

        this.generatePreview = await this.revalidate();

        return this.setData()
            .then(() => {
                if (foreground) {
                    this.onSave();
                }
            })
            .catch(() => {
                if (foreground) {
                    this.notifyError();
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    async autoSave() {
        return this.setData().catch(error => {
            if (!error.isIntercepted) {
                this.$store.dispatch('notification/error', error);
            }
        });
    }

    async setData() {
        if (this.canCreateInterviewStylePodcast) {
            await this.saveAboutCompany();
            await this.saveContentSources(false);
        }

        return this.$http
            .post(this.sourceUrl, this.getDataToSave())
            .then(({ data }) => data)
            .then(({ data }) => {
                if (data?.errors) {
                    throw new Error(
                        'Unable to save Podcast. Please check the form for errors.'
                    );
                }

                return data;
            })
            .then(data => this.commit(data));
    }

    getDataToSave() {
        return {
            id: this.podcast.id,
            headline: this.podcast.headline,
            summary: this.podcast.summary,
            media_resources: this.podcast.media_resources,
            preview: this.generatePreview,
            podcast_captions: this.podcast?.podcast_captions,
            topic_1: this.podcast.topic_1,
            topic_2: this.podcast.topic_2,
            style_type: this.podcast.style_type,
            audio_clip_url: this.podcast.audio_clip_url
        };
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    async setSaved() {
        return this.$refs.form.reset();
    }

    async onSave() {
        const isValid = await this.revalidate();

        if (isValid) {
            this.review();
        }
    }

    async revalidate() {
        // reset existing errors
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-publish-podcast`
        );

        const isValid = await this.$refs.form.validate();

        if (!isValid) {
            this.notifyInvalid();
        }

        if (this.generatingTranscript) {
            return false;
        }

        return isValid;
    }

    review() {
        this.$router.push(
            `/announcements/review/${this.announcementId}/podcast`
        );
    }

    protectRoute() {
        if (!this.podcast.is_editable) {
            this.review();
        }
    }

    emitLinks(reset = false) {
        if (reset) {
            this.$emit('links', []);
        }

        this.$nextTick(() => {
            this.$emit('links', this.link);
        });
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.setPrePublishHook(false);

        return this.save(false).then(() => this.canLeaveRoute(to, next, true));
    }

    async canLeaveRoute(
        to: Route,
        next: NavigationGuardNext,
        needsValidation = false
    ) {
        if (this.podcast.is_editable && to.path === this.reviewLink) {
            if (needsValidation) {
                await this.$refs.form.validate();
            }

            if (!this.isValid) {
                this.notifyInvalid();

                this.emitLinks(true);
                // no reason to switch to preview
                return next(false);
            }
        }

        return next();
    }

    prePublish() {
        if (!this.$refs.form?.isDirty) {
            return this.revalidate();
        }

        this.setSaving();

        return this.setData()
            .then(() => this.revalidate())
            .catch(() => {
                this.notifyError();

                return false;
            })
            .finally(this.setSaving.bind(this, false));
    }

    notifyInvalid() {
        this.$store.dispatch(
            'notification/info',
            'Podcast saved successfully. Please check the form for errors.'
        );
    }

    notifyError() {
        this.$store.dispatch(
            'notification/error',
            'Unable to save Podcast. Please check the form for errors.'
        );
    }

    onMediaFilesUpdate(resources: FormMediaResource[]) {
        this.podcast.media_resources = [...resources];

        this.$refs.media_resources.setFlags({
            dirty: true,
            changed: true,
            touched: true
        });
    }

    openGenerateTranscriptModal() {
        if (this.hasTranscript) {
            this.$store.dispatch('modal/open', 'GenerateTranscriptModal');
        } else {
            this.generateTranscript();
        }
    }

    async generateTranscript() {
        this.$store.dispatch('modal/close', 'GenerateTranscriptModal');

        const isValid = await this.$refs.form.validate();

        if (!isValid) {
            this.$refs.form.scrollToFirstInvalidControl();
            return;
        }

        await this.setData();

        this.isGenerating = true;
        const contentSourcesSaved = await this.saveContentSources().then(
            success => {
                if (success) {
                    return true;
                }

                this.isGenerating = false;
                return false;
            }
        );

        if (contentSourcesSaved) {
            this._generateTranscript();
        }
    }

    _generateTranscript() {
        const url = `/podcasts/transcript/${this.moduleId}`;
        this.transcriptErrors = [];

        this.$http
            .post(url, { annonucement_id: this.announcementId })
            .then(() => {
                if (this.hasTranscript) {
                    this.showTranscript = true;
                }

                this.reload();
                this.setAutoCheckStatusInterval();
            })
            .catch(error => {
                this.$store.dispatch(
                    'notification/error',
                    'Cannot generate. Please review errors and try again.'
                );
                const { errors } = error.response.data;
                this.transcriptErrors = errors;
            })
            .finally(() => {
                this.isGenerating = false;
            });
    }

    openGenerateStandardPodcastModal() {
        this.$store.dispatch('modal/open', 'GenerateStandardPodcastModal');
    }

    generateStandardPodcast() {
        const url = `/podcasts/reset/${this.moduleId}`;

        this.$http
            .get(url)
            .then(() => {
                this.$store.dispatch(
                    'modal/close',
                    'GenerateStandardPodcastModal'
                );
                this.showTranscript = false;
                this.load();
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    onCancelStandardPodcastGeneration() {
        this.podcast.style_type = PodcastStyleType.INTERVIEW;
    }

    onCancelGeneratePodcastModal() {
        this.podcast.style_type = PodcastStyleType.STANDARD;
    }

    setAutoCheckStatusInterval() {
        this.clearAutoCheckStatusInterval();

        this.autoCheckStatusInterval = setInterval(
            this.search.bind(this),
            3000
        );
    }

    clearAutoCheckStatusInterval() {
        if (this.autoCheckStatusInterval) {
            clearInterval(this.autoCheckStatusInterval);
        }
    }

    search() {
        this.setLoading();

        this.$http
            .get(`/podcasts/search?announcement_id=${this.announcementId}`)
            .then(({ data }) => {
                const { podcast }: { podcast: TPodcast } = data.data;

                if (!podcast) {
                    this.clearAutoCheckStatusInterval();
                    this.askToCreate();
                    return;
                }

                if (podcast.status !== PodcastStatus.GeneratingTranscript) {
                    this.clearAutoCheckStatusInterval();
                    this.reload();
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    beforeDestroy() {
        this.clearAutoCheckStatusInterval();
    }

    setPodcastGeneration() {
        this.setAutoCheckStatusInterval();
    }

    async saveAboutCompany() {
        const {
            company: { id, contact_name }
        } = this.podcast.announcement as Announcement;

        if (id) {
            await this.$http.post(
                `/companies/edit/${id}?user_id=${this.podcast.announcement?.user_id}`,
                {
                    about: this.aboutCompany,
                    contact_name // required to pass BE validation
                }
            );
        }
    }

    initInterviewStylePodcast() {
        if (this.generatingTranscript) {
            this.hideBackgroundInfo();
            this.setAutoCheckStatusInterval();
        }

        if (this.hasTranscript) {
            this.showTranscript = true;
            this.hideBackgroundInfo();
        }

        this.$nextTick(() => {
            this.checkContentSourcesForErrors(this.contentSources);
        });
    }

    reload() {
        // resets isInitialized flag, so new data can be applied
        this.podcast.id = 0;

        this.$emit('reload');
    }

    async saveContentSources(process = true) {
        const url = [
            '/content_sources/add',
            this.announcementId,
            `?process=${process ? 'true' : 'false'}`
        ].join('/');

        return this.$http
            .post(url, {
                content_sources: this.contentSources
            })
            .then(({ data }) => {
                const {
                    content_sources,
                    meta
                }: {
                    content_sources: ContentSource[];
                    meta: { success: boolean };
                } = data.data;

                this.contentSources = content_sources;

                if (!meta.success) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save content sources'
                    );
                    this.checkContentSourcesForErrors(content_sources);
                }

                return meta.success;
            });
    }

    checkContentSourcesForErrors(sources: ContentSource[]) {
        sources.forEach((source, i) => {
            if (source.status === ContentSourceStatus.Failed) {
                this.showBackgroundInfo();
                this.$refs.form.$refs.observer.setErrors({
                    [`content_source_${i}`]:
                        'Unable to reach the URL. Please review or provide a different URL.'
                });
            }
        });
    }

    showBackgroundInfo() {
        this.expandBackgroundInfo = true;
    }

    hideBackgroundInfo() {
        this.expandBackgroundInfo = false;
    }

    getSpeakerVoiceId(speakerType: SpeakerType) {
        const caption = this.captions.find(
            caption => caption.speaker_type === speakerType
        );

        if (caption) {
            return caption.video_voice_id;
        }

        return speakerType === SpeakerType.SpeakerTypeSpeakerOne
            ? this.defaultSpeakerVoices.speaker_one
            : this.defaultSpeakerVoices.speaker_two;
    }

    updateCaptionVoiceForSpeaker(speakerType: SpeakerType, voiceId: number) {
        this.podcast.podcast_captions?.map(caption => {
            if (caption.speaker_type === speakerType) {
                caption.video_voice_id = voiceId;
            }

            return caption;
        });
    }

    getVoiceSample(voiceId: number) {
        return (
            this.video_voices.find(voice => voice.id === voiceId)?.route || ''
        );
    }

    getVoiceName(voiceId: number) {
        return this.video_voices.find(voice => voice.id === voiceId)
            ?.voice_name;
    }

    getVoiceAvatarSrc(voiceId: number) {
        return require(`@/assets/img/voice-avatars/${voiceId}.png`);
    }

    disableSelectedSpeakerTwoVoice(videoVoice: VideoVoice) {
        return videoVoice.id === this.speakerTwoVoiceId;
    }

    disableSelectedSpeakerOneVoice(videoVoice: VideoVoice) {
        return videoVoice.id === this.speakerOneVoiceId;
    }
}
</script>

<style lang="scss" scoped>
.transcript-caption::v-deep {
    display: flex;
    gap: 0.5em;
    padding: 0.25em;
    margin-bottom: 0.25em;

    &.host {
        background-color: $main-background !important;
    }

    &.sponsor {
        background-color: $secondary-color-10 !important;
    }

    &:hover {
        opacity: 0.9;
    }

    .content {
        width: 100%;
    }
}
</style>
