<template>
    <modal :id="id" title="Generate" :closable="closable">
        <div class="d-flex align-center">
            <v-icon x-large color="#ff8a5b">circle-exclamation</v-icon>

            <p class="text-body-1 ma-0 ml-4">
                Generating a standard podcast will remove the current
                transcript.
                <br />
                This action cannot be undone. Are you sure you want to proceed?
            </p>
        </div>

        <template #actions>
            <div class="d-flex" :style="{ width: '100%' }">
                <v-spacer />
                <v-btn text :disabled="loading" @click="onCancel">Cancel</v-btn>
                <v-btn color="primary" :loading="loading" @click="onConfirm">
                    Yes, Generate
                </v-btn>
            </div>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { AAlert } from '@/components/AAlert';

const GenerateStandardPodcastModalProps = Vue.extend({
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        Modal,
        AAlert
    }
})
export default class GenerateStandardPodcastModal extends GenerateStandardPodcastModalProps {
    id = 'GenerateStandardPodcastModal';

    closable = false;

    onCancel() {
        this.$emit('cancel');
        this.$store.dispatch('modal/close', this.id);
    }

    onConfirm() {
        this.$emit('confirm');
    }
}
</script>
